import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    addCredential,
    removeCredential
} from '../../redux/slices/office-slice.js';
import useCredentials from '../../hooks/useCredentials.js';
import CreateUpdateCredentialForm from './CreateUpdateCredentialForm.jsx';
import ConfirmationModal from '../common/ConfirmationModal.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';
import { black, grey, red } from '../../styles/standard-colors.js';
import ToggleVisibilityButton from '../common/ToggleVisibilityButton.jsx';
import checkPwHipaaCompliance from '../../utils/check-pw-hipaa-compliance.js';
import TableTitleHeader from '../common/TableTitleHeader.jsx';

/**
 * Component for displaying credential information for a computer.
 * @param {object} props - The component props.
 * @param {object} props.credential - The credential object.
 * @param {string} props.computerId - The ID of the computer associated with the credential.
 * @param {boolean} props.computerIsRetired - Indicates whether the associated computer is retired.
 * @returns {JSX.Element} JSX element representing the Credential Information Table.
 */
const CredentialInfoTable = ({ credential, computerId, computerIsRetired }) => {
    const dispatch = useDispatch();

    const [showEdit, setShowEdit] = useState(false);
    const [showDeleteCredentialModal, setShowDeleteCredentialModal] =
        useState(false);
    const [showDuplicateCredentialModal, setShowDuplicateCredentialModal] =
        useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {
        error: credentialError2,
        loading: credentialLoading2,
        deleteCredential,
        duplicateCredential
    } = useCredentials();

    /**
     * Handles the toggle of the password visibility.
     */
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    /**
     * Handles the duplication of the credential.
     * @returns {void}
     */
    const handleCredentialDuplication = async () => {
        setShowDuplicateCredentialModal(false);

        const duplicatedCredential = await duplicateCredential(credential.id);

        // Check for failure
        if (!duplicatedCredential) {
            return;
        }

        dispatch(addCredential(duplicatedCredential));
    };

    /**
     * Handles the deletion of the credential.
     * @returns {void}
     */
    const handleDelete = async () => {
        setShowDeleteCredentialModal(false);

        await deleteCredential(credential.id);

        dispatch(
            removeCredential({
                computerId,
                credentialId: credential.id
            })
        );
    };

    return (
        <>
            <div className="office-computer-credentials-container">
                {showEdit ? (
                    <CreateUpdateCredentialForm
                        objectToUpdate={credential}
                        setShowEdit={setShowEdit}
                        computerId={computerId}
                    />
                ) : (
                    <table
                        className="office-table"
                        style={{ marginBottom: '1em', width: '60%' }}>
                        <tbody
                            style={{ color: computerIsRetired ? grey : black }}>
                            <tr>
                                <td className="office-table-left-cell">
                                    Username:
                                </td>
                                <td className="office-table-right-cell">
                                    {credential.username}
                                </td>
                            </tr>
                            <tr>
                                <td className="office-table-left-cell">
                                    Password:
                                </td>
                                <td
                                    className="office-table-right-cell-pwd"
                                    style={
                                        !checkPwHipaaCompliance(
                                            credential.password
                                        )
                                            ? { color: red }
                                            : undefined
                                    }>
                                    {showPassword
                                        ? credential.password
                                        : '*'.repeat(
                                              credential.password.length
                                          )}
                                    <ToggleVisibilityButton
                                        condition={showPassword}
                                        onClick={handleTogglePassword}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="office-table-left-cell">
                                    Domain:
                                </td>
                                <td className="office-table-right-cell">
                                    {credential.domain
                                        ? credential.domain
                                        : 'N/A'}
                                </td>
                            </tr>
                            {credential.scannerModel && (
                                <tr>
                                    <td className="office-table-left-cell">
                                        Scanner Model:
                                    </td>
                                    <td className="office-table-right-cell">
                                        {credential.scannerModel}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
                <div className="office-credential-btns">
                    <button
                        className="office-credential-btn"
                        onClick={() => setShowEdit(true)}>
                        Edit
                    </button>
                    <button
                        className="office-credential-btn"
                        onClick={() => setShowDeleteCredentialModal(true)}>
                        Delete
                    </button>
                    <button
                        className="office-credential-btn"
                        onClick={() => setShowDuplicateCredentialModal(true)}>
                        Duplicate
                    </button>
                </div>
            </div>
            {credentialLoading2 && <LoadingSpinner />}
            {credentialError2 && (
                <ErrorMessage message={credentialError2.message} />
            )}
            <TableTitleHeader customContainerStyles={{ marginLeft: '30px' }} />
            {showDeleteCredentialModal && (
                <ConfirmationModal
                    title="Delete Credential"
                    message="Are you sure you want to delete this credential?"
                    onConfirm={handleDelete}
                    setShowModal={setShowDeleteCredentialModal}
                />
            )}
            {showDuplicateCredentialModal && (
                <ConfirmationModal
                    title="Duplicate Credential"
                    message="Are you sure you want to duplicate this credential?"
                    onConfirm={handleCredentialDuplication}
                    setShowModal={setShowDuplicateCredentialModal}
                />
            )}
        </>
    );
};

CredentialInfoTable.propTypes = {
    credential: PropTypes.object.isRequired,
    computerId: PropTypes.string.isRequired,
    computerIsRetired: PropTypes.bool.isRequired
};

export default CredentialInfoTable;
