import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import CheckBox from 'rc-checkbox';
import DatePicker from 'react-datepicker';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    LineChart
} from 'recharts';
import SubmitButton from '../common/SubmitButton.jsx';
import { runQueryFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useUsers from '../../hooks/useUsers.js';
import {
    timePeriodOptions,
    userHelpDeskTeamOptions
} from '../../config/dropdown-options.js';
import runQueryValidation from '../../validation/run-query-validation.js';
import StatTable from './StatTable.jsx';
import useReporting from '../../hooks/useReporting.js';

/**
 * HdQueryForm component for running an HD query.
 *
 * @component
 * @returns {JSX.Element} - The rendered HdQueryForm component.
 */
const HdQueryForm = () => {
    const [userOptions, setUserOptions] = useState([]);
    const [showCustomTime, setShowCustomTime] = useState(false);
    const [teamQueryActive, setTeamQueryActive] = useState(false);

    const { error: userError, loading: userLoading, searchUsers } = useUsers();

    const {
        error: reportingError,
        loading: reportingLoading,
        table,
        charts,
        runQuery,
        reset
    } = useReporting();

    useEffect(() => {
        const getAllUsers = async () => {
            let returnedUsers = [];

            const helpDeskUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b158e' // Help desk
            );
            const phoneTechUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b1590' // Phone tech
            );

            const adminTechUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b1591' // Admin tech
            );

            returnedUsers = returnedUsers.concat(
                helpDeskUsers,
                phoneTechUsers,
                adminTechUsers
            );

            returnedUsers = returnedUsers.map((u) => {
                // eslint-disable-next-line no-param-reassign
                u = {
                    value: u.id,
                    label: `${u.firstName} ${u.lastName}`
                };
                return u;
            });

            // Add all as an option
            returnedUsers.unshift({
                value: `000000000000000000000000`,
                label: `All`
            });

            setUserOptions(returnedUsers);
        };

        getAllUsers();
    }, []);

    const formik = useFormik({
        initialValues: runQueryFormFields,
        validationSchema: runQueryValidation,
        onSubmit: async (values) => {
            const modifiedValues = { ...values };

            // Get rid of null fields or empty arrays
            Object.keys(modifiedValues).forEach((key) => {
                if (
                    modifiedValues[key] === null ||
                    (Array.isArray(modifiedValues[key]) &&
                        modifiedValues[key].length === 0)
                ) {
                    delete modifiedValues[key];
                }
            });

            await runQuery(modifiedValues);
        }
    });

    const handleClickLeft = () => {
        if (teamQueryActive) {
            setTeamQueryActive(false);
            reset();
            formik.setFieldValue('userId', '000000000000000000000000');
            formik.setFieldValue('teamId', null);
        }
    };

    const handleClickRight = () => {
        if (!teamQueryActive) {
            setTeamQueryActive(true);
            reset();
            formik.setFieldValue('userId', '000000000000000000000000');
            formik.setFieldValue('teamId', null);
        }
    };

    useEffect(() => {
        formik.setFieldValue('itSegment', 'hd');
    }, []);

    useEffect(() => {
        if (showCustomTime) {
            formik.setFieldValue('timePeriod', 'custom');
        } else {
            formik.setFieldValue('timePeriod', '');
        }
    }, [showCustomTime]);

    return (
        <>
            {/* Form and table results */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '50vh',
                    marginBottom: '5vh'
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%'
                    }}>
                    {/* Toggle button for team vs individual query */}
                    <div
                        style={{
                            display: 'flex',
                            width: '50%',
                            height: '40px',
                            marginBottom: '2em',
                            marginLeft: '30px',
                            marginTop: '2em'
                        }}>
                        <div
                            className={
                                teamQueryActive
                                    ? 'reporting-query-selector-inactive'
                                    : 'reporting-query-selector-active'
                            }
                            onClick={handleClickLeft}
                            id="reporting-query-selector-left">
                            <p
                                style={{
                                    color: !teamQueryActive
                                        ? 'white'
                                        : '#2361DC'
                                }}>
                                Individual
                            </p>
                        </div>
                        <div
                            className={
                                teamQueryActive
                                    ? 'reporting-query-selector-active'
                                    : 'reporting-query-selector-inactive'
                            }
                            id="reporting-query-selector-right"
                            onClick={handleClickRight}>
                            <p
                                style={{
                                    color: teamQueryActive ? 'white' : '#2361DC'
                                }}>
                                Team
                            </p>
                        </div>
                    </div>

                    {/* Individual Query Form */}
                    {!teamQueryActive && (
                        <form
                            onSubmit={formik.handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginLeft: '30px',
                                marginTop: '2em'
                            }}>
                            <div
                                style={{
                                    textAlign: 'left'
                                }}>
                                <label htmlFor="userId">User</label>
                                <br />
                                <br />
                                <div style={{ width: '60%' }}>
                                    <Select
                                        id="userId"
                                        name="userId"
                                        options={userOptions}
                                        value={userOptions.find(
                                            (option) =>
                                                option.value ===
                                                formik.values.userId
                                        )}
                                        onChange={(selectedOption) =>
                                            formik.setFieldValue(
                                                'userId',
                                                selectedOption
                                                    ? selectedOption.value
                                                    : null
                                            )
                                        }
                                    />
                                </div>
                                {userLoading && <LoadingSpinner />}
                                {userError && (
                                    <ErrorMessage message={userError.message} />
                                )}
                                {formik.touched.userId &&
                                formik.errors.userId ? (
                                    <ErrorMessage
                                        message={formik.errors.userId}
                                    />
                                ) : null}
                                <br />

                                <label htmlFor="timePeriod">Time Period</label>
                                <br />
                                <br />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '80%',
                                        justifyContent: 'space-between'
                                    }}>
                                    {showCustomTime ? (
                                        <div className="hipaa-date-container">
                                            <div
                                                className="hipaa-date-chunk"
                                                style={{ zIndex: 0 }}>
                                                <p>Start Date</p>
                                                <DatePicker
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'customStartDate',
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        formik.values
                                                            .customStartDate
                                                    }
                                                    maxDate={new Date()}
                                                    minDate={
                                                        new Date('2015-10-27')
                                                    }
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                            <div
                                                className="hipaa-date-chunk"
                                                style={{ zIndex: 0 }}>
                                                <p>End Date</p>
                                                <DatePicker
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'customEndDate',
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        formik.values
                                                            .customEndDate
                                                    }
                                                    maxDate={new Date()}
                                                    minDate={
                                                        new Date('2015-10-27')
                                                    }
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ width: '75%' }}>
                                            <Select
                                                id="timePeriod"
                                                name="timePeriod"
                                                options={timePeriodOptions}
                                                value={timePeriodOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        formik.values.timePeriod
                                                )}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue(
                                                        'timePeriod',
                                                        selectedOption
                                                            ? selectedOption.value
                                                            : null
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                        <CheckBox
                                            checked={showCustomTime}
                                            onChange={() =>
                                                setShowCustomTime(
                                                    !showCustomTime
                                                )
                                            }
                                        />
                                        <p>Use Custom</p>
                                    </div>
                                </div>
                                {formik.touched.timePeriod &&
                                formik.errors.timePeriod ? (
                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}>
                                        <ErrorMessage
                                            message={formik.errors.timePeriod}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <br />
                            <br />
                            {Object.keys(formik.errors).length !== 0 &&
                            Object.keys(formik.touched).length !== 0 ? (
                                <ErrorMessage message="There are form errors above, please fix." />
                            ) : null}

                            <SubmitButton label="Run query" />
                        </form>
                    )}

                    {/* Team Query Form */}
                    {teamQueryActive && (
                        <form
                            onSubmit={formik.handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginLeft: '30px',
                                marginTop: '2em'
                            }}>
                            <div
                                style={{
                                    textAlign: 'left'
                                }}>
                                <label htmlFor="teamId">Team</label>
                                <br />
                                <br />
                                <div style={{ width: '60%' }}>
                                    <Select
                                        id="teamId"
                                        name="teamId"
                                        options={userHelpDeskTeamOptions}
                                        value={userHelpDeskTeamOptions.find(
                                            (option) =>
                                                option.value ===
                                                formik.values.teamId
                                        )}
                                        onChange={(selectedOption) =>
                                            formik.setFieldValue(
                                                'teamId',
                                                selectedOption
                                                    ? selectedOption.value
                                                    : null
                                            )
                                        }
                                    />
                                </div>
                                {formik.touched.teamId &&
                                formik.errors.teamId ? (
                                    <ErrorMessage
                                        message={formik.errors.teamId}
                                    />
                                ) : null}
                                <br />

                                <label htmlFor="timePeriod">Time Period</label>
                                <br />
                                <br />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '80%',
                                        justifyContent: 'space-between'
                                    }}>
                                    {showCustomTime ? (
                                        <div className="hipaa-date-container">
                                            <div
                                                className="hipaa-date-chunk"
                                                style={{ zIndex: 0 }}>
                                                <p>Start Date</p>
                                                <DatePicker
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'customStartDate',
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        formik.values
                                                            .customStartDate
                                                    }
                                                    maxDate={new Date()}
                                                    minDate={
                                                        new Date('2015-10-27')
                                                    }
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                            <div
                                                className="hipaa-date-chunk"
                                                style={{ zIndex: 0 }}>
                                                <p>End Date</p>
                                                <DatePicker
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'customEndDate',
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        formik.values
                                                            .customEndDate
                                                    }
                                                    maxDate={new Date()}
                                                    minDate={
                                                        new Date('2015-10-27')
                                                    }
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ width: '75%' }}>
                                            <Select
                                                id="timePeriod"
                                                name="timePeriod"
                                                options={timePeriodOptions}
                                                value={timePeriodOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        formik.values.timePeriod
                                                )}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue(
                                                        'timePeriod',
                                                        selectedOption
                                                            ? selectedOption.value
                                                            : null
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                        <CheckBox
                                            checked={showCustomTime}
                                            onChange={() =>
                                                setShowCustomTime(
                                                    !showCustomTime
                                                )
                                            }
                                        />
                                        <p>Use Custom</p>
                                    </div>
                                </div>
                                {formik.touched.timePeriod &&
                                formik.errors.timePeriod ? (
                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}>
                                        <ErrorMessage
                                            message={formik.errors.timePeriod}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <br />
                            <br />
                            {Object.keys(formik.errors).length !== 0 &&
                            Object.keys(formik.touched).length !== 0 ? (
                                <ErrorMessage message="There are form errors above, please fix." />
                            ) : null}

                            <SubmitButton label="Run query" />
                        </form>
                    )}
                </div>

                <br />

                {/* Table results */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        alignItems: 'center'
                    }}>
                    {reportingLoading && <LoadingSpinner />}
                    {reportingError && (
                        <ErrorMessage message={reportingError.message} />
                    )}
                    {table && (
                        <>
                            <StatTable
                                title={table.title}
                                rows={table.rows}
                                columnHeaders={table.headers}
                            />
                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-evenly'
                                }}>
                                <button
                                    className="newsc-btn"
                                    style={{ marginBottom: '5em' }}
                                    onClick={reset}>
                                    Reset
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Chart results */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '50vh',
                    alignItems: 'center'
                }}>
                {' '}
                {charts && charts.length > 0 && (
                    <>
                        <h3 style={{ fontStyle: 'italic' }}>
                            Tickets Closed/Tasks Completed
                        </h3>
                        <ResponsiveContainer width="95%" height={450}>
                            <BarChart
                                width={500}
                                height={300}
                                data={charts}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Tickets Closed" fill="#155180" />
                                <Bar
                                    dataKey="Tickets Outside SLA"
                                    fill="#cd5c5c"
                                />
                                <Bar dataKey="Tasks Completed" fill="#ffa500" />
                                <Bar
                                    dataKey="Tasks Outside SLA"
                                    fill="#1CAC78"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h3 style={{ fontStyle: 'italic' }}>
                            % of Tickets/Tasks Outside SLA
                        </h3>
                        <ResponsiveContainer width="95%" height={450}>
                            <LineChart
                                width={500}
                                height={300}
                                data={charts}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="linear"
                                    dataKey="% of Tickets Outside SLA"
                                    fill="#155180"
                                    stroke="#155180"
                                />
                                <Line
                                    type="linear"
                                    dataKey="% of Tasks Outside SLA"
                                    fill="#cd5c5c"
                                    stroke="#cd5c5c"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </>
                )}
            </div>
        </>
    );
};

export default HdQueryForm;
