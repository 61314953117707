import * as Yup from 'yup';
import {
    validIpv4Address,
    validOfficeSysName,
    validUsPhoneNumber
} from './custom-validations.js';

/**
 * Yup validation schema for creating an office.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} officeName - Validation for the office name.
 * @property {Yup.StringSchema} officeSysName - Validation for the office systematic name.
 * @property {Yup.StringSchema} officeEmail - Validation for the office email address.
 * @property {Yup.StringSchema} itName - Validation for the IT name.
 * @property {Yup.StringSchema} itPhoneNumber - Validation for the IT phone number.
 * @property {Yup.StringSchema} itEmail - Validation for the IT email address.
 * @property {Yup.StringSchema} connectionSoftware - Validation for the connection software.
 * @property {Yup.NumberSchema} hamachiNetwork - Validation for the Hamachi network.
 * @property {Yup.StringSchema} hamachiNetworkId - Validation for the Hamachi network ID.
 * @property {Yup.StringSchema} hamachiNetworkPassword - Validation for the Hamachi network password.
 * @property {Yup.StringSchema} dentalSoftware - Validation for the dental software.
 * @property {Yup.StringSchema} dsUsername - Validation for the dental software username.
 * @property {Yup.StringSchema} dsPassword - Validation for the dental software password.
 * @property {Yup.StringSchema} dsUsername2 - Validation for the second dental software username.
 * @property {Yup.StringSchema} dsPassword2 - Validation for the second dental software password.
 * @property {Yup.StringSchema} xrays - Validation for the X-rays.
 * @property {Yup.StringSchema} eClaims - Validation for the eClaims.
 * @property {Yup.StringSchema} eClaimsUsername - Validation for the eClaims username.
 * @property {Yup.StringSchema} eClaimsPassword - Validation for the eClaims password.
 * @property {Yup.StringSchema} eClaimsInstructions - Validation for the eClaims instructions.
 * @property {Yup.StringSchema} practiceBoosterIp - Validation for the PracticeBooster IP address.
 * @property {Yup.StringSchema} practiceBoosterUsername - Validation for the PracticeBooster username.
 * @property {Yup.StringSchema} practiceBoosterPassword - Validation for the PracticeBooster password.
 * @property {Yup.StringSchema} pdfPrinter - Validation for the PDF printer.
 * @property {Yup.StringSchema} attachments - Validation for the attachments.
 * @property {Yup.StringSchema} phoneNumber - Validation for the phone number.
 * @property {Yup.StringSchema} production - Validation for the production.
 * @property {Yup.StringSchema} installTech - Validation for the installation technician.
 * @property {Yup.DateSchema} installDate - Validation for the installation date.
 * @property {Yup.NumberSchema} numOfDBConnections - Validation for the number of database connections.
 * @property {Yup.NumberSchema} numOfIVConnections - Validation for the number of IV connections.
 * @property {Yup.NumberSchema} numOfPPConnections - Validation for the number of PP connections.
 * @property {Yup.NumberSchema} numOfFSConnections - Validation for the number of FS connections.
 * @property {Yup.StringSchema} eobPath - Validation for the EOB path.
 * @property {Yup.StringSchema} notes - Validation for the office notes.
 * @property {Yup.ObjectSchema} uniqueVPN - Validation for the unique VPN configuration.
 * @property {Yup.BooleanSchema} uniqueVPN.hasUniqueVPN - Validation for whether the office has a unique VPN.
 * @property {Yup.BooleanSchema} uniqueVPN.addendumOnFile - Validation for whether an addendum is on file.
 * @property {Yup.StringSchema} firewallInfo - Validation for the firewall information.
 * @property {Yup.StringSchema} backupTimes - Validation for the backup times.
 * @property {Yup.StringSchema} poc - Validation for the point of contact (POC).
 * @property {Yup.BooleanSchema} isKoc - Validation for whether it's a Key Operator Console (KOC).
 * @property {Yup.StringSchema} dsURL - Validation for the dental software URL.
 * @property {Yup.BooleanSchema} isCopilotOffice - Validation for whether it's a CoPilot office.
 * @property {Yup.StringSchema} copilotServerName - Validation for the CoPilot server name.
 * @property {Yup.StringSchema} copilotServerUsername - Validation for the CoPilot server username.
 * @property {Yup.StringSchema} copilotServerPassword - Validation for the CoPilot server password.
 * @property {Yup.StringSchema} copilotNotes - Validation for the CoPilot notes.
 */
const createOfficeValidation = Yup.object({
    officeName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .strict(),
    officeSysName: Yup.string()
        .required('Required')
        .matches(validOfficeSysName, {
            message:
                'Must match the following format: ut.shire.baggins, representing the state, city, and doctor last name. If multiple words, should be separated by underscore e.g. ut.the_shire.baggins. Should be all lowercase.'
        })
        .trim('Remove any leading or trailing spaces'),
    officeEmail: Yup.string().trim('Remove any leading or trailing spaces'),
    itName: Yup.string().trim('Remove any leading or trailing spaces'),
    itPhoneNumber: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .matches(validUsPhoneNumber, {
            message:
                'Must be a valid US phone number in the following format: (801) 123-1234'
        }),
    itEmail: Yup.string().trim('Remove any leading or trailing spaces'),
    connectionSoftware: Yup.string().trim(
        'Remove any leading or trailing spaces'
    ),
    hamachiNetwork: Yup.number().integer().nullable(),
    hamachiNetworkId: Yup.string().trim(
        'Remove any leading or trailing spaces'
    ),
    hamachiNetworkPassword: Yup.string(),
    dentalSoftware: Yup.string().trim('Remove any leading or trailing spaces'),
    dsUsername: Yup.string(),
    dsPassword: Yup.string(),
    dsUsername2: Yup.string(),
    dsPassword2: Yup.string(),
    xrays: Yup.string().trim('Remove any leading or trailing spaces'),
    eClaims: Yup.string().trim('Remove any leading or trailing spaces'),
    eClaimsUsername: Yup.string(),
    eClaimsPassword: Yup.string(),
    eClaimsInstructions: Yup.string(),
    practiceBoosterIp: Yup.string()
        .matches(validIpv4Address, {
            message: 'Must be a valid IPv4 address e.g. 127.0.0.1'
        })
        .trim('Remove any leading or trailing spaces'),
    practiceBoosterUsername: Yup.string(),
    practiceBoosterPassword: Yup.string(),
    pdfPrinter: Yup.string().trim('Remove any leading or trailing spaces'),
    attachments: Yup.string().trim('Remove any leading or trailing spaces'),
    phoneNumber: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .matches(validUsPhoneNumber, {
            message:
                'Must be a valid US phone number in the following format: (801) 123-1234'
        }),
    production: Yup.string().trim('Remove any leading or trailing spaces'),
    installTech: Yup.string(),
    installDate: Yup.date().nullable(),
    numOfDBConnections: Yup.number().integer(),
    numOfIVConnections: Yup.number().integer(),
    numOfPPConnections: Yup.number().integer(),
    numOfFSConnections: Yup.number().integer(),
    eobPath: Yup.string(),
    notes: Yup.string(),
    uniqueVPN: Yup.object({
        hasUniqueVPN: Yup.boolean().required('Required'),
        addendumOnFile: Yup.boolean().required('Required')
    }),
    firewallInfo: Yup.string(),
    backupTimes: Yup.string(),
    poc: Yup.string().trim('Remove any leading or trailing spaces'),
    isKoc: Yup.boolean(),
    dsURL: Yup.string().trim('Remove any leading or trailing spaces'),
    isCopilotOffice: Yup.boolean(),
    copilotServerName: Yup.string().trim(
        'Remove any leading or trailing spaces'
    ),
    copilotServerUsername: Yup.string(),
    copilotServerPassword: Yup.string(),
    copilotNotes: Yup.string()
});

export default createOfficeValidation;
