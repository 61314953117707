import * as Yup from 'yup';

/**
 * Validation schema for creating a new credential.
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} username - Validation for the username.
 * @property {Yup.StringSchema} password - Validation for the password.
 * @property {Yup.StringSchema} domain - Validation for the domain.
 * @property {Yup.StringSchema} scannerModel - Validation for the scanner model.
 */
const createCredentialValidation = Yup.object({
    username: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Username is required'),
    password: Yup.string().required('Password is required'),
    domain: Yup.string().trim('Remove any leading or trailing spaces'),
    scannerModel: Yup.string().trim('Remove any leading or trailing spaces')
});

export default createCredentialValidation;
