import PropTypes from 'prop-types';
import {
    green,
    grey,
    pinkRed,
    red,
    yellow
} from '../../styles/standard-colors.js';
import AddButton from './AddButton.jsx';
import SettingsGear from './SettingsGear.jsx';
import BackArrow from './BackArrow.jsx';
import ActiveStateDot from './ActiveStateDot.jsx';

/**
 * Renders a table title header with optional features like back arrow, settings gear, and add button.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.includeBackArrow - Whether to include a back arrow.
 * @param {string} props.title - The title of the table header.
 * @param {boolean} props.hasSettingsGear - Whether to include a settings gear icon.
 * @param {string} props.settingsGearColor - The color of the settings gear icon.
 * @param {number} props.settingsGearSize - The size of the settings gear icon.
 * @param {Function} props.setShowDropdown - Function to control the visibility of a dropdown menu.
 * @param {boolean} props.showDropdown - Whether the dropdown menu is visible.
 * @param {boolean} props.includeAddButton - Whether to include an add button.
 * @param {Function} props.handleAddButton - Function to handle the click event of the add button.
 * @param {string} props.addButtonColor - The color of the add button.
 * @param {number} props.addButtonSize - The size of the add button.
 * @param {string} props.underlineColor - The color of the underline border.
 * @param {boolean} props.isRetired - Whether the table header is retired.
 * @param {boolean} props.hasActiveStateDot - Whether to include an active state dot.
 * @param {number} props.activeStateDotSize - The size of the active state dot.
 * @param {Object} props.customContainerStyles - Custom styles for the container.
 * @returns {JSX.Element} JSX element representing the table title header.
 */
const TableTitleHeader = ({
    includeBackArrow,
    title,
    hasSettingsGear,
    settingsGearColor,
    settingsGearSize,
    setShowDropdown,
    showDropdown,
    includeAddButton,
    handleAddButton,
    addButtonColor,
    addButtonSize,
    underlineColor,
    isRetired,
    hasActiveStateDot,
    activeStateDotSize,
    customContainerStyles
}) => {
    return (
        <>
            <div
                className="office-header-container"
                style={{
                    borderBottom: `3px solid ${underlineColor}`,
                    ...customContainerStyles
                }}>
                {includeBackArrow && <BackArrow navigateTo={-1} />}
                <h2 style={isRetired ? { color: grey } : null}>{title}</h2>
                <div>
                    {hasActiveStateDot && (
                        <ActiveStateDot
                            size={activeStateDotSize}
                            color={isRetired ? red : green}
                        />
                    )}
                    {hasSettingsGear && (
                        <SettingsGear
                            size={settingsGearSize}
                            color={settingsGearColor}
                            onClick={() => setShowDropdown(!showDropdown)}
                        />
                    )}
                    {includeAddButton && (
                        <AddButton
                            size={addButtonSize}
                            color={addButtonColor}
                            onClick={handleAddButton}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

TableTitleHeader.propTypes = {
    includeBackArrow: PropTypes.bool,
    title: PropTypes.string,
    hasSettingsGear: PropTypes.bool,
    setShowDropdown: PropTypes.func,
    showDropdown: PropTypes.bool,
    settingsGearColor: PropTypes.string,
    settingsGearSize: PropTypes.number,
    includeAddButton: PropTypes.bool,
    handleAddButton: PropTypes.func,
    addButtonColor: PropTypes.string,
    addButtonSize: PropTypes.number,
    underlineColor: PropTypes.string,
    isRetired: PropTypes.bool,
    hasActiveStateDot: PropTypes.bool,
    activeStateDotSize: PropTypes.number,
    customContainerStyles: PropTypes.object
};

TableTitleHeader.defaultProps = {
    title: '',
    includeBackArrow: false,
    hasSettingsGear: false,
    settingsGearColor: yellow,
    settingsGearSize: 34,
    includeAddButton: false,
    addButtonColor: pinkRed,
    addButtonSize: 34,
    underlineColor: pinkRed,
    isRetired: false,
    hasActiveStateDot: false,
    activeStateDotSize: 40,
    customContainerStyles: {}
};

export default TableTitleHeader;
