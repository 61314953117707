import SubLinks from '../../components/header/SubLinks.jsx';
import { reportingLinks } from '../../config/standard-links.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import HdQueryForm from '../../components/reporting/HdQueryForm.jsx';

/**
 * Component for rendering the page for HD Query tool.
 * @returns {JSX.Element} HdQueryToolPage component.
 */
const HdQueryToolPage = () => {
    return (
        <div>
            <SubLinks links={reportingLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>HD Query</h2>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        width: '100%'
                    }}>
                    <HdQueryForm />
                </div>
            </div>
        </div>
    );
};

export default HdQueryToolPage;
