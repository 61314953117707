import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import CheckBox from 'rc-checkbox';
import DatePicker from 'react-datepicker';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    LineChart
} from 'recharts';
import { v4 as uuidv4 } from 'uuid';
import SubmitButton from '../common/SubmitButton.jsx';
import { runQueryFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useUsers from '../../hooks/useUsers.js';
import { timePeriodOptions } from '../../config/dropdown-options.js';
import runQueryValidation from '../../validation/run-query-validation.js';
import StatTable from './StatTable.jsx';
import chartColors from '../../styles/chart-colors.js';
import useReporting from '../../hooks/useReporting.js';

/**
 * NcltQueryForm component for running a NCLT query.
 *
 * @component
 * @returns {JSX.Element} - The rendered NcltQueryForm component.
 */
const NcltQueryForm = () => {
    const [userOptions, setUserOptions] = useState([]);
    const [showCustomTime, setShowCustomTime] = useState(false);

    const { error: userError, loading: userLoading, searchUsers } = useUsers();

    const {
        error: reportingError,
        loading: reportingLoading,
        tables,
        charts,
        runQuery,
        reset
    } = useReporting();

    useEffect(() => {
        const getAllUsers = async () => {
            let returnedUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b1593' // Office Install
            );
            returnedUsers = returnedUsers.map((u) => {
                // eslint-disable-next-line no-param-reassign
                u = {
                    value: u.id,
                    label: `${u.firstName} ${u.lastName}`
                };
                return u;
            });

            // Add all as an option
            returnedUsers.unshift({
                value: `000000000000000000000000`,
                label: `All`
            });

            setUserOptions(returnedUsers);
        };

        getAllUsers();
    }, []);

    const formik = useFormik({
        initialValues: runQueryFormFields,
        validationSchema: runQueryValidation,
        onSubmit: async (values) => {
            const modifiedValues = { ...values };

            // Get rid of null fields or empty arrays
            Object.keys(modifiedValues).forEach((key) => {
                if (
                    modifiedValues[key] === null ||
                    (Array.isArray(modifiedValues[key]) &&
                        modifiedValues[key].length === 0)
                ) {
                    delete modifiedValues[key];
                }
            });

            await runQuery(modifiedValues);
        }
    });

    useEffect(() => {
        formik.setFieldValue('itSegment', 'nclt');
    }, []);

    useEffect(() => {
        if (showCustomTime) {
            formik.setFieldValue('timePeriod', 'custom');
        } else {
            formik.setFieldValue('timePeriod', '');
        }
    }, [showCustomTime]);

    return (
        <>
            {/* Form and table results */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '50vh',
                    marginBottom: '5vh'
                }}>
                {/* Form */}
                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        marginLeft: '30px',
                        marginTop: '2em'
                    }}>
                    <div
                        style={{
                            textAlign: 'left'
                        }}>
                        <label htmlFor="userId">User</label>
                        <br />
                        <br />
                        <div style={{ width: '60%' }}>
                            <Select
                                id="userId"
                                name="userId"
                                options={userOptions}
                                value={userOptions.find(
                                    (option) =>
                                        option.value === formik.values.userId
                                )}
                                onChange={(selectedOption) =>
                                    formik.setFieldValue(
                                        'userId',
                                        selectedOption
                                            ? selectedOption.value
                                            : null
                                    )
                                }
                            />
                        </div>
                        {userLoading && <LoadingSpinner />}
                        {userError && (
                            <ErrorMessage message={userError.message} />
                        )}
                        {formik.touched.userId && formik.errors.userId ? (
                            <ErrorMessage message={formik.errors.userId} />
                        ) : null}
                        <br />

                        <label htmlFor="timePeriod">Time Period</label>
                        <br />
                        <br />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '80%',
                                justifyContent: 'space-between'
                            }}>
                            {showCustomTime ? (
                                <div className="hipaa-date-container">
                                    <div
                                        className="hipaa-date-chunk"
                                        style={{ zIndex: 0 }}>
                                        <p>Start Date</p>
                                        <DatePicker
                                            onChange={(date) =>
                                                formik.setFieldValue(
                                                    'customStartDate',
                                                    date
                                                )
                                            }
                                            selected={
                                                formik.values.customStartDate
                                            }
                                            maxDate={new Date()}
                                            minDate={new Date('2015-10-27')}
                                            isClearable
                                            showYearDropdown
                                            scrollableMonthYearDropdown
                                        />
                                    </div>
                                    <div
                                        className="hipaa-date-chunk"
                                        style={{ zIndex: 0 }}>
                                        <p>End Date</p>
                                        <DatePicker
                                            onChange={(date) =>
                                                formik.setFieldValue(
                                                    'customEndDate',
                                                    date
                                                )
                                            }
                                            selected={
                                                formik.values.customEndDate
                                            }
                                            maxDate={new Date()}
                                            minDate={new Date('2015-10-27')}
                                            isClearable
                                            showYearDropdown
                                            scrollableMonthYearDropdown
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div style={{ width: '75%' }}>
                                    <Select
                                        id="timePeriod"
                                        name="timePeriod"
                                        options={timePeriodOptions}
                                        value={timePeriodOptions.find(
                                            (option) =>
                                                option.value ===
                                                formik.values.timePeriod
                                        )}
                                        onChange={(selectedOption) =>
                                            formik.setFieldValue(
                                                'timePeriod',
                                                selectedOption
                                                    ? selectedOption.value
                                                    : null
                                            )
                                        }
                                    />
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                <CheckBox
                                    checked={showCustomTime}
                                    onChange={() =>
                                        setShowCustomTime(!showCustomTime)
                                    }
                                />
                                <p>Use Custom</p>
                            </div>
                        </div>
                        {formik.touched.timePeriod &&
                        formik.errors.timePeriod ? (
                            <div
                                style={{
                                    textAlign: 'center'
                                }}>
                                <ErrorMessage
                                    message={formik.errors.timePeriod}
                                />
                            </div>
                        ) : null}
                    </div>
                    <br />
                    <br />
                    {Object.keys(formik.errors).length !== 0 &&
                    Object.keys(formik.touched).length !== 0 ? (
                        <ErrorMessage message="There are form errors above, please fix." />
                    ) : null}

                    <SubmitButton label="Run query" />
                </form>
                <br />

                {/* Table results */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        alignItems: 'center'
                    }}>
                    {reportingLoading && <LoadingSpinner />}
                    {reportingError && (
                        <ErrorMessage message={reportingError.message} />
                    )}
                    {tables && tables.length > 0 && (
                        <>
                            {tables.map((table) => (
                                <StatTable
                                    title={table.title}
                                    key={uuidv4()}
                                    rows={table.rows}
                                    columnHeaders={table.headers}
                                />
                            ))}
                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-evenly'
                                }}>
                                <button
                                    className="newsc-btn"
                                    style={{ marginBottom: '5em' }}
                                    onClick={reset}>
                                    Reset
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Chart results */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '50vh',
                    alignItems: 'center'
                }}>
                {' '}
                {charts && charts.length > 0 && (
                    <>
                        <h3
                            key={`days-in-it-chart`}
                            style={{ fontStyle: 'italic' }}>
                            Avg Days in IT
                        </h3>
                        <ResponsiveContainer width="95%" height={450}>
                            <LineChart
                                width={500}
                                height={300}
                                data={charts}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    dataKey="Total Avg Days in IT"
                                    fill="#155180"
                                    stroke="#155180"
                                />
                                {tables[1].rows.map((service, index) => (
                                    <Line
                                        key={uuidv4()}
                                        dataKey={`${service[0].data} Avg Days in IT`}
                                        fill={chartColors[index]}
                                        stroke={chartColors[index]}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h3 key={`5days-chart`} style={{ fontStyle: 'italic' }}>
                            % Completed Outside 5 Days
                        </h3>
                        <ResponsiveContainer width="95%" height={450}>
                            <LineChart
                                width={500}
                                height={300}
                                data={charts}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    dataKey="Total % Completed Outside 5 Days"
                                    fill="#155180"
                                    stroke="#155180"
                                />
                                {tables[1].rows.map((service, index) => (
                                    <Line
                                        key={uuidv4()}
                                        dataKey={`${service[0].data} % Completed Outside 5 Days`}
                                        fill={chartColors[index]}
                                        stroke={chartColors[index]}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h3
                            key={`launches-chart`}
                            style={{ fontStyle: 'italic' }}>
                            Launches
                        </h3>
                        <ResponsiveContainer width="95%" height={450}>
                            <BarChart
                                width={500}
                                height={300}
                                data={charts}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Total Launches" fill="#155180" />
                                {tables[1].rows.map((service, index) => (
                                    <Bar
                                        key={uuidv4()}
                                        dataKey={`${service[0].data} Launches`}
                                        fill={chartColors[index]}
                                    />
                                ))}
                            </BarChart>
                        </ResponsiveContainer>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h3
                            key={`avg-launch-time-chart`}
                            style={{ fontStyle: 'italic' }}>
                            Avg Launch Time (Days)
                        </h3>
                        <ResponsiveContainer width="95%" height={450}>
                            <LineChart
                                width={500}
                                height={300}
                                data={charts}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    dataKey="Total Avg Launch Time (Days)"
                                    fill="#155180"
                                    stroke="#155180"
                                />
                                {tables[1].rows.map((service, index) => (
                                    <Line
                                        key={uuidv4()}
                                        dataKey={`${service[0].data} Avg Launch Time (Days)`}
                                        fill={chartColors[index]}
                                        stroke={chartColors[index]}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                    </>
                )}
            </div>
        </>
    );
};

export default NcltQueryForm;
