import * as Yup from 'yup';
import { validEassistEmail, validUsPhoneNumber } from './custom-validations.js';

/**
 * Yup validation schema for creating a new SC card.
 */
const createNewScValidation = Yup.object({
    firstName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    lastName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    eAssistEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validEassistEmail, {
            message: 'Must be a valid eAssist Email (@eassist.me)'
        }),
    personalEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    phone: Yup.string()
        .matches(validUsPhoneNumber, {
            message:
                'Must be a valid US phone number in this format: (801) 123-1234'
        })
        .trim('Remove any leading or trailing spaces'),
    scNotes: Yup.string()
});

export default createNewScValidation;
