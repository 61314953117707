/* eslint-disable import/extensions */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { BiCalendarWeek } from 'react-icons/bi';
import { MdSupportAgent, MdQueryStats, MdAddToQueue } from 'react-icons/md';
import { BsUpload } from 'react-icons/bs';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { reportingLinks } from '../../config/standard-links.js';
// import LeaderBoardContainer from './LeaderBoardContainer';
// import GraphsContainer from './GraphsContainer';
// import RandomStatsContainer from './RandomStatsContainer';
import { blue, pinkRed } from '../../styles/standard-colors.js';
import DashboardUser from '../../components/reporting/DashboardUser.jsx';

/**
 * Component for rendering the Reporting Dashboard page.
 * @returns {JSX.Element} ReportingDashboardPage component.
 */
const ReportingDashboardPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setActiveTab('Reporting'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={reportingLinks} />
            <div className="reporting-home-container">
                <div className="reporting-home-row">
                    <div
                        className="reporting-home-tile"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            height: '200px'
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '10px',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <MdSupportAgent size={70} />
                            <h3>Help Desk</h3>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                marginRight: '10px',
                                cursor: 'pointer'
                            }}>
                            <h3 style={{ color: pinkRed }}>Explore Data</h3>
                            <MdQueryStats
                                size={34}
                                color={pinkRed}
                                onClick={() => navigate('/hdQueryTool')}
                                style={{ cursor: 'pointer' }}
                            />
                            <h3 style={{ color: blue }}>Weekly Stats</h3>
                            <BiCalendarWeek
                                size={34}
                                color={blue}
                                onClick={() => navigate('/hdWeeklyStats')}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <div
                        className="reporting-home-tile"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            height: '200px'
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '10px',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <AiOutlineUserAdd size={70} />
                            <h3>New SC</h3>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                marginRight: '10px'
                            }}>
                            <h3 style={{ color: pinkRed }}>Explore Data</h3>
                            <MdQueryStats
                                size={34}
                                color={pinkRed}
                                onClick={() => navigate('/temporary')}
                                style={{ cursor: 'pointer' }}
                            />
                            <h3 style={{ color: blue }}>Weekly Stats</h3>
                            <BiCalendarWeek
                                size={34}
                                color={blue}
                                onClick={() => navigate('/newscweeklystats')}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <div
                        className="reporting-home-tile"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            height: '200px'
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '10px',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <MdAddToQueue size={70} />
                            <h3>NCLT</h3>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                marginRight: '10px'
                            }}>
                            <h3 style={{ color: pinkRed }}>Explore Data</h3>
                            <MdQueryStats
                                size={34}
                                color={pinkRed}
                                onClick={() => navigate('/ncltQueryTool')}
                                style={{ cursor: 'pointer' }}
                            />
                            <h3 style={{ color: blue }}>Weekly Stats</h3>
                            <BiCalendarWeek
                                size={34}
                                color={blue}
                                onClick={() => navigate('/ncltweeklystats')}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="reporting-home-row" style={{ height: '615px' }}>
                    <div
                        className="reporting-home-tile"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        {/* <LeaderBoardContainer /> */}
                        <h3>Deactivated at the moment</h3>
                    </div>
                    <div
                        className="reporting-home-tile"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white'
                        }}>
                        <div className="gears-container">
                            <div className="gear-rotate" />
                        </div>
                    </div>
                    <Link
                        to="/temporary"
                        className="reporting-home-tile"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textDecoration: 'none'
                        }}>
                        <BsUpload size={70} color={blue} />
                        <h4 style={{ textDecoration: 'none', color: 'black' }}>
                            Upload Tickets
                        </h4>
                    </Link>
                </div>
                <div className="reporting-home-row" style={{ height: '400px' }}>
                    <div
                        className="reporting-home-tile"
                        style={{ height: '100%' }}>
                        <DashboardUser />
                    </div>
                    <div
                        className="reporting-home-tile"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        {/* <GraphsContainer /> */}
                        <h3>Deactivated at the moment</h3>
                    </div>
                    <div
                        className="reporting-home-tile"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        {/* <RandomStatsContainer /> */}
                        <h3>Deactivated at the moment</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportingDashboardPage;
