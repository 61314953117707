import { useFormik } from 'formik';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SubmitButton from '../common/SubmitButton.jsx';
import { createCredentialFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import {
    addCredential,
    updateCredential as updateCredentialRedux
} from '../../redux/slices/office-slice.js';
import useCredentials from '../../hooks/useCredentials.js';
import createCredentialValidation from '../../validation/create-credential-validation.js';
import ToggleVisibilityButton from '../common/ToggleVisibilityButton.jsx';

/**
 * CreateUpdateCredentialForm component for rendering the form to create a new credential or update an existing one.
 *
 * @component
 * @returns {JSX.Element} - The rendered CreateUpdateCredentialForm component.
 */
const CreateUpdateCredentialForm = ({
    objectToUpdate,
    setShowEdit,
    setShowCreate,
    computerId
}) => {
    const dispatch = useDispatch();

    const {
        error: credentialError,
        loading: credentialLoading,
        credential,
        createCredential,
        updateCredential
    } = useCredentials();

    const formik = useFormik({
        initialValues: objectToUpdate || createCredentialFormFields,
        validationSchema: createCredentialValidation,
        onSubmit: async (values) => {
            // Take out showPassword and id when submitting
            const { showPassword, id, hipaaCompliant, ...formValues } = values;

            if (objectToUpdate) {
                const updatedCredential = await updateCredential(
                    objectToUpdate.id,
                    formValues
                );
                if (updatedCredential) {
                    dispatch(
                        updateCredentialRedux({
                            ...updatedCredential,
                            computerId: updatedCredential.computerId
                        })
                    );
                    setShowEdit(false);
                }
            } else {
                const newCredential = await createCredential(formValues);
                if (newCredential) {
                    dispatch(addCredential(newCredential));
                }
            }
        }
    });

    /**
     * Handles the toggle of the password visibility.
     */
    const handleTogglePassword = () => {
        formik.setFieldValue('showPassword', !formik.values.showPassword);
    };

    useEffect(() => {
        formik.setFieldValue('computerId', computerId);
    }, [computerId]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <table className="office-table">
                    <tbody>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="username">Username:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="username"
                                    type="text"
                                    className="office-computer-edit-input"
                                    placeholder="e.g. admin"
                                    {...formik.getFieldProps('username')}
                                />
                            </td>
                            {formik.touched.username &&
                            formik.errors.username ? (
                                <ErrorMessage
                                    message={formik.errors.username}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="password">Password:</label>
                            </td>
                            <td className="office-table-right-cell-pwd">
                                <input
                                    id="password"
                                    type={
                                        formik.values.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('password')}
                                />
                                <ToggleVisibilityButton
                                    condition={formik.values.showPassword}
                                    onClick={handleTogglePassword}
                                />
                            </td>
                            {formik.touched.password &&
                            formik.errors.password ? (
                                <ErrorMessage
                                    autofocus
                                    message={formik.errors.password}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="domain">Domain:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="domain"
                                    type="text"
                                    placeholder="e.g. Workgroup"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('domain')}
                                />
                            </td>
                            {formik.touched.domain && formik.errors.domain ? (
                                <ErrorMessage message={formik.errors.domain} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="scannerModel">
                                    Scanner Model:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="scannerModel"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('scannerModel')}
                                />
                            </td>
                            {formik.touched.scannerModel &&
                            formik.errors.scannerModel ? (
                                <ErrorMessage
                                    message={formik.errors.scannerModel}
                                />
                            ) : null}
                        </tr>
                    </tbody>
                </table>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <SubmitButton
                        label="Submit"
                        style={{ marginLeft: '30px' }}
                    />
                    {objectToUpdate ? (
                        <button
                            className="submit-btn"
                            style={{ marginLeft: '30px' }}
                            onClick={() => setShowEdit(false)}>
                            Cancel
                        </button>
                    ) : (
                        <button
                            className="submit-btn"
                            style={{ marginLeft: '30px' }}
                            onClick={() => setShowCreate(false)}>
                            Cancel
                        </button>
                    )}
                </div>
            </form>
            <br />
            <br />
            {credentialLoading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 &&
            Object.keys(formik.touched).length !== 0 ? (
                <ErrorMessage message="There are form errors above, please fix." />
            ) : null}
            {credentialError && (
                <ErrorMessage message={credentialError.message} />
            )}
            {credential && objectToUpdate ? (
                <SuccessMessage message="Credential successfully updated" />
            ) : null}
            {credential && !objectToUpdate ? (
                <SuccessMessage message="Credential successfully created" />
            ) : null}
        </>
    );
};

CreateUpdateCredentialForm.propTypes = {
    objectToUpdate: PropTypes.object,
    setShowEdit: PropTypes.func,
    setShowCreate: PropTypes.func,
    computerId: PropTypes.string.isRequired
};

export default CreateUpdateCredentialForm;
