import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { updateComputerRetiredState } from '../../redux/slices/office-slice.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useComputers from '../../hooks/useComputers.js';

/**
 * Component for displaying settings dropdown for a computer.
 *
 * @param {object} props - The props object.
 * @param {object} props.computer - The computer object.
 * @param {boolean} props.showEdit - Boolean indicating whether edit mode is active.
 * @param {function} props.setShowEdit - Function to toggle edit mode.
 * @param {boolean} props.showDuplicateComputerModal - Boolean indicating whether duplicate computer modal is visible.
 * @param {function} props.setShowDuplicateComputerModal - Function to toggle visibility of duplicate computer modal.
 * @param {string} props.officeSysName - The office system name.
 * @returns {JSX.Element} JSX element representing the Computer Settings Dropdown.
 */
const ComputerSettingsDropdown = ({
    computer,
    showEdit,
    setShowEdit,
    showDuplicateComputerModal,
    setShowDuplicateComputerModal,
    officeSysName
}) => {
    const dispatch = useDispatch();

    const { error, loading, updateComputer } = useComputers();

    /**
     * Handler function for toggling the retired state of the computer.
     */
    const handleChangeActiveState = async () => {
        const updatedComputer = await updateComputer(
            computer.id,
            { retired: !computer.retired },
            computer,
            officeSysName
        );
        if (!updatedComputer) {
            return;
        }

        dispatch(
            updateComputerRetiredState({
                computerId: updatedComputer.id,
                retiredState: updatedComputer.retired
            })
        );
    };

    return (
        <>
            <div className="computer-settings-dropdown-container">
                <div className="computer-settings-dropdown">
                    <p
                        className="computer-settings-dropdown-item"
                        onClick={() => setShowEdit(!showEdit)}>
                        {showEdit ? 'Cancel Edit' : 'Edit'}
                    </p>
                    <p
                        className="computer-settings-dropdown-item"
                        onClick={() => handleChangeActiveState()}>
                        {computer.retired ? 'Re-activate' : 'Retire'}
                    </p>
                    <p
                        className="computer-settings-dropdown-item"
                        onClick={() =>
                            setShowDuplicateComputerModal(
                                !showDuplicateComputerModal
                            )
                        }>
                        Duplicate
                    </p>
                </div>
            </div>
            {error && <ErrorMessage message={error.message} />}
            {loading && <LoadingSpinner />}
        </>
    );
};

ComputerSettingsDropdown.propTypes = {
    computer: PropTypes.object.isRequired,
    showEdit: PropTypes.bool.isRequired,
    setShowEdit: PropTypes.func.isRequired,
    showDuplicateComputerModal: PropTypes.bool.isRequired,
    setShowDuplicateComputerModal: PropTypes.func.isRequired,
    officeSysName: PropTypes.string.isRequired
};

export default ComputerSettingsDropdown;
