import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Component for displaying computer information.
 *
 * @param {object} props - The props object.
 * @param {string} props.systematicName - The systematic name of the computer.
 * @param {string} props.OS - The operating system of the computer.
 * @param {string} props.location - The location of the computer.
 * @param {Array<object>} props.credentials - An array of credentials associated with the computer.
 * @param {boolean} props.isAvInstalled - Indicates if antivirus is installed on the computer.
 * @param {string} props.avName - The name of the antivirus installed on the computer.
 * @returns {JSX.Element} JSX element representing the Computer Information.
 */
const CopyComputerInfo = ({
    systematicName,
    OS,
    location,
    credentials,
    isAvInstalled,
    avName
}) => {
    return (
        <>
            <p>Computer Systematic Name: {systematicName}</p>
            <p>OS: {OS}</p>
            {isAvInstalled ? (
                <>
                    <p>AV is installed</p>
                    <p>AV Name: {avName}</p>
                </>
            ) : (
                <p>AV is not installed</p>
            )}
            <p style={{ marginTop: '0em' }}>Office Location: {location}</p>
            {credentials.map((credential) => (
                <Fragment key={credential._id}>
                    <p>Username: {credential.username}</p>
                    <p>Password: {credential.password}</p>
                    <p>Domain: {credential.domain}</p>
                </Fragment>
            ))}
        </>
    );
};

CopyComputerInfo.propTypes = {
    systematicName: PropTypes.string.isRequired,
    OS: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    credentials: PropTypes.arrayOf(PropTypes.object).isRequired,
    isAvInstalled: PropTypes.bool.isRequired,
    avName: PropTypes.string.isRequired
};

export default CopyComputerInfo;
