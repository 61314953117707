import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    selectOfficeObj,
    setOfficeObj,
    updateOfficeActiveState,
    updateOfficeSecureProtocol
} from '../../redux/slices/office-slice.js';
import useOffices from '../../hooks/useOffices.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';

/**
 * Component for displaying a settings dropdown menu for office settings.
 * @param {object} props - The props object.
 * @param {boolean} props.showCopy - Indicates whether to show the copy option.
 * @param {function} props.setShowCopy - Function to toggle the copy option.
 * @param {boolean} props.showEdit - Indicates whether to show the edit option.
 * @param {function} props.setShowEdit - Function to toggle the edit option.
 * @param {function} props.handleCopy - Function to handle copying office information.
 * @param {boolean} props.showDuplicateOfficeNoteModal - Indicates whether to show the duplicate office note modal.
 * @param {function} props.setShowDuplicateOfficeNoteModal - Function to toggle the duplicate office note modal.
 * @returns {JSX.Element} JSX element representing the Settings Dropdown.
 */
const SettingsDropdown = ({
    showCopy,
    setShowCopy,
    showEdit,
    setShowEdit,
    handleCopy,
    showDuplicateOfficeNoteModal,
    setShowDuplicateOfficeNoteModal
}) => {
    const dispatch = useDispatch();
    const officeObj = useSelector(selectOfficeObj);

    const { error, loading, updateOffice } = useOffices();

    /**
     * Handles the change in secure protocol status for the office.
     * @returns {void}
     */
    const handleChangeInSecureProtocol = async () => {
        const updatedOffice = await updateOffice(
            officeObj.id,
            { inSecureProtocol: !officeObj.inSecureProtocol },
            officeObj
        );
        if (!updatedOffice) {
            return;
        }
        dispatch(updateOfficeSecureProtocol(updatedOffice.inSecureProtocol));
    };

    /**
     * Handles the change in active state for the office.
     * @returns {void}
     */
    const handleChangeActiveState = async () => {
        const updatedOffice = await updateOffice(
            officeObj.id,
            { active: !officeObj.active },
            officeObj
        );
        if (!updatedOffice) {
            return;
        }
        dispatch(updateOfficeActiveState(updatedOffice.active));
    };

    /**
     * Handles the change in HIPAA exemption status for OS.
     * @returns {void}
     */
    const handleChangeOsExempt = async () => {
        const updatedOffice = await updateOffice(
            officeObj.id,
            { hcOSExempt: !officeObj.hcOSExempt },
            officeObj
        );
        if (!updatedOffice) {
            return;
        }
        dispatch(setOfficeObj(updatedOffice));
    };

    /**
     * Handles the change in HIPAA exemption status for passwords.
     * @returns {void}
     */
    const handleChangePasswordExempt = async () => {
        const updatedOffice = await updateOffice(
            officeObj.id,
            { hcPasswordsExempt: !officeObj.hcPasswordsExempt },
            officeObj
        );
        if (!updatedOffice) {
            return;
        }
        dispatch(setOfficeObj(updatedOffice));
    };

    /**
     * Handles the change in HIPAA exemption status for AV.
     * @returns {void}
     */
    const handleChangeAvExempt = async () => {
        const updatedOffice = await updateOffice(
            officeObj.id,
            { hcAVExempt: !officeObj.hcAVExempt },
            officeObj
        );
        if (!updatedOffice) {
            return;
        }
        dispatch(setOfficeObj(updatedOffice));
    };

    /**
     * Handles the change in HIPAA exemption status for VPN.
     * @returns {void}
     */
    const handleChangeVPNExempt = async () => {
        const updatedOffice = await updateOffice(
            officeObj.id,
            { hcVPNExempt: !officeObj.hcVPNExempt },
            officeObj
        );
        if (!updatedOffice) {
            return;
        }
        dispatch(setOfficeObj(updatedOffice));
    };

    return (
        <>
            <div className="office-settings-dropdown-container">
                <div className="office-settings-dropdown">
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => setShowEdit(!showEdit)}>
                        {showEdit ? 'Cancel Edit' : 'Edit Office Info'}
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => setShowCopy(!showCopy)}>
                        {showCopy ? 'Hide Copy Info' : 'Copy Office Info'}
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => handleChangeActiveState()}>
                        {officeObj.active ? 'Retire Office' : 'Activate Office'}
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => handleCopy()}>
                        Copy Hamachi Info
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => handleChangeInSecureProtocol()}>
                        {officeObj.inSecureProtocol
                            ? 'Take out of Secure Protocol'
                            : 'Put In Secure Protocol'}
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() =>
                            setShowDuplicateOfficeNoteModal(
                                !showDuplicateOfficeNoteModal
                            )
                        }>
                        Duplicate Office Note
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => handleChangeOsExempt()}>
                        {officeObj.hcOSExempt
                            ? 'Remove OS HIPAA exemption'
                            : 'Flag as exempt from OS HIPAA'}
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => handleChangePasswordExempt()}>
                        {officeObj.hcPasswordsExempt
                            ? 'Remove password HIPAA exemption'
                            : 'Flag as exempt from password HIPAA'}
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => handleChangeAvExempt()}>
                        {officeObj.hcAVExempt
                            ? 'Remove AV HIPAA Exemption'
                            : 'Flag as exempt from AV HIPAA'}
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => handleChangeVPNExempt()}>
                        {officeObj.hcVPNExempt
                            ? 'Remove VPN HIPAA Exemption'
                            : 'Flag as exempt from VPN HIPAA'}
                    </p>
                </div>
            </div>
            {error && <ErrorMessage message={error.message} />}
            {loading && <LoadingSpinner />}
        </>
    );
};

SettingsDropdown.propTypes = {
    showCopy: PropTypes.bool.isRequired,
    setShowCopy: PropTypes.func.isRequired,
    showEdit: PropTypes.bool.isRequired,
    setShowEdit: PropTypes.func.isRequired,
    handleCopy: PropTypes.func.isRequired,
    showDuplicateOfficeNoteModal: PropTypes.bool.isRequired,
    setShowDuplicateOfficeNoteModal: PropTypes.func.isRequired
};

export default SettingsDropdown;
