import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { addComputer } from '../../redux/slices/office-slice.js';
import useComputers from '../../hooks/useComputers.js';
import TableTitleHeader from '../common/TableTitleHeader.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';
import ConfirmationModal from '../common/ConfirmationModal.jsx';
import ComputerSettingsDropdown from './ComputerSettingsDropdown.jsx';
import { black, blue, grey, pinkRed } from '../../styles/standard-colors.js';
import CreateUpdateComputerForm from './CreateUpdateComputerForm.jsx';
import CreateUpdateCredentialForm from './CreateUpdateCredentialForm.jsx';
import CredentialInfoTable from './CredentialInfoTable.jsx';

/**
 * Component for displaying computer information in a table format.
 *
 * @param {object} props - The props object.
 * @param {object} props.computer - The computer object.
 * @param {string} props.officeSysName - The office system name.
 * @param {string} props.officeId - The office ID.
 * @returns {JSX.Element} JSX element representing the Computer Info Table.
 */
const ComputerInfoTable = ({ computer, officeSysName, officeId }) => {
    const dispatch = useDispatch();
    const [showCreateCredential, setShowCreateCredential] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDuplicateComputerModal, setShowDuplicateComputerModal] =
        useState(false);
    const {
        error: computerError,
        loading: computerLoading,
        duplicateComputer
    } = useComputers();

    /**
     * Handler function for duplicating a computer.
     */
    const handleDuplication = async () => {
        setShowDuplicateComputerModal(false);

        const duplicatedComputer = await duplicateComputer(computer.id);

        // Check for failure
        if (!duplicatedComputer) {
            return;
        }

        dispatch(addComputer({ ...duplicatedComputer, credentials: [] }));
    };

    return (
        <div>
            <div className="office-computer-header">
                <TableTitleHeader
                    title={computer.computerSysName}
                    hasSettingsGear={true}
                    settingsGearSize={25}
                    setShowDropdown={setShowDropdown}
                    showDropdown={showDropdown}
                    isRetired={!!computer.retired}
                    hasActiveStateDot={true}
                    underlineColor={blue}
                    customContainerStyles={{ fontSize: '15px' }}
                />
                {showDropdown && (
                    <ComputerSettingsDropdown
                        computer={computer}
                        showEdit={showEdit}
                        setShowEdit={setShowEdit}
                        showDuplicateComputerModal={showDuplicateComputerModal}
                        setShowDuplicateComputerModal={
                            setShowDuplicateComputerModal
                        }
                        officeSysName={officeSysName}
                    />
                )}
                {computerLoading && <LoadingSpinner />}
                {computerError && (
                    <ErrorMessage message={computerError.message} />
                )}
            </div>
            {showEdit ? (
                <CreateUpdateComputerForm
                    objectToUpdate={computer}
                    setShowEdit={setShowEdit}
                    officeId={officeId}
                    officeSysName={officeSysName}
                />
            ) : (
                <table className="office-computer-table">
                    <tbody style={{ color: computer.retired ? grey : black }}>
                        <tr>
                            <td className="office-computer-table-left-cell">
                                Computer Systematic Name:
                            </td>
                            <td className="office-computer-table-right-cell">
                                {computer.computerSysName}
                            </td>
                        </tr>
                        <tr>
                            <td className="office-computer-table-left-cell">
                                Computer Name:
                            </td>
                            <td className="office-computer-table-right-cell">
                                {computer.computerName}
                            </td>
                        </tr>
                        <tr>
                            <td className="office-computer-table-left-cell">
                                Operating System:
                            </td>
                            <td className="office-computer-table-right-cell">
                                {computer.OS}
                            </td>
                        </tr>
                        <tr>
                            <td className="office-computer-table-left-cell">
                                AV Installed?:
                            </td>
                            <td className="office-computer-table-right-cell">
                                {computer.avInstalled ? 'Yes' : 'No'}
                            </td>
                        </tr>
                        <tr>
                            <td className="office-computer-table-left-cell">
                                Antivirus Name:
                            </td>
                            <td className="office-computer-table-right-cell">
                                {computer.avName}
                            </td>
                        </tr>
                        <tr>
                            <td className="office-computer-table-left-cell">
                                Domain:
                            </td>
                            <td className="office-computer-table-right-cell">
                                {computer.domain}
                            </td>
                        </tr>
                        <tr>
                            <td className="office-computer-table-left-cell">
                                Location:
                            </td>
                            <td className="office-computer-table-right-cell">
                                {computer.location}
                            </td>
                        </tr>
                        <tr>
                            <td className="office-computer-table-left-cell">
                                Syncro Status:
                            </td>
                            <td className="office-computer-table-right-cell">
                                {computer.syncroStatus}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderRight: '1px #DCDCDC solid',
                                    padding: '.5em'
                                }}>
                                Note:
                            </td>
                            <td
                                style={{
                                    padding: '.5em',
                                    whiteSpace: 'pre-wrap'
                                }}>
                                {computer.note}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
            <TableTitleHeader
                title="Credentials"
                includeAddButton={true}
                addButtonColor={blue}
                addButtonSize={20}
                underlineColor={pinkRed}
                handleAddButton={() => setShowCreateCredential(true)}
                customContainerStyles={{ marginLeft: '30px', fontSize: '12px' }}
            />
            {showCreateCredential && (
                <CreateUpdateCredentialForm
                    setShowCreate={setShowCreateCredential}
                    computerId={computer.id}
                />
            )}
            {computer.credentials.map((cred) => {
                return (
                    <CredentialInfoTable
                        key={uuidv4()}
                        credential={cred}
                        computerId={computer.id}
                        computerIsRetired={computer.retired}
                    />
                );
            })}
            {showDuplicateComputerModal && (
                <ConfirmationModal
                    title="Duplicate Computer"
                    message="Are you sure you want to duplicate this computer?"
                    onConfirm={handleDuplication}
                    setShowModal={setShowDuplicateComputerModal}
                />
            )}
        </div>
    );
};

ComputerInfoTable.propTypes = {
    computer: PropTypes.object.isRequired,
    officeSysName: PropTypes.string.isRequired,
    officeId: PropTypes.string.isRequired
};

export default ComputerInfoTable;
