import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { adminLinks } from '../../config/standard-links.js';

/**
 * Component representing the admin automations home page.
 * @returns {JSX.Element} AdminAutomationsHomePage component.
 */
const AdminAutomationsHomePage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('Admin'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={adminLinks} />
            <div className="admin-container">
                <h2 className="admin-title">Admin Automations</h2>
                <ul className="admin-links-container">
                    <Link to="/oac" className="automation-link">
                        Sharefile OAC
                    </Link>
                    <Link to="/oar" className="automation-link">
                        Sharefile OAR
                    </Link>
                    <Link to="/userRemoval" className="automation-link">
                        User Removal
                    </Link>
                    <Link to="/aoar" className="automation-link">
                        AOAR
                    </Link>
                    <Link to="/emailJobs" className="automation-link">
                        Email Jobs
                    </Link>
                </ul>
            </div>
        </div>
    );
};

export default AdminAutomationsHomePage;
