/**
 * Navigation links for the app header.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const headerLinks = [
    { title: 'Notes', to: '/notes' },
    { title: 'New SC', to: '/newsc' },
    { title: 'Admin', to: '/admin' },
    { title: 'HIPAA', to: '/hipaa' },
    { title: 'Reporting', to: '/reporting' },
    { title: 'Install Fails', to: '/installfails' },
    { title: 'Users', to: '/users' },
    { title: 'Logout', to: '/logout' }
];

/**
 * Navigation links for the 'New SC' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const newscLinks = [
    {
        title: 'Add New SC',
        to: '/addSC'
    },
    {
        title: 'Send Orientation Complete Email',
        to: '/completeOrientation'
    }
];

/**
 * Navigation links for the 'Admin' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const adminLinks = [
    {
        title: 'Sharefile OAC',
        to: '/oac'
    },
    {
        title: 'User Removal',
        to: '/userRemoval'
    },
    {
        title: 'Sharefile OAR',
        to: '/oar'
    },
    {
        title: 'AOAR',
        to: '/aoar'
    },
    {
        title: 'Email Jobs',
        to: '/emailJobs'
    }
];

/**
 * Navigation links for the 'HIPAA' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const hipaaLinks = [
    {
        title: 'Concerns',
        to: '/hipaaconcerns'
    },
    {
        title: 'Corrections',
        to: '/hipaacorrections'
    },
    {
        title: 'Send OS Fail Email',
        to: '/hipaaOsEmail'
    }
];

/**
 * Navigation links for the 'User Management' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const userManagementLinks = [
    {
        title: 'Create a User',
        to: '/createuser'
    },
    {
        title: 'Update a User',
        to: '/updateuser'
    },
    {
        title: 'Delete a User',
        to: '/deleteuser'
    }
];

/**
 * Navigation links for the 'Reporting' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const reportingLinks = [
    {
        title: 'Home',
        to: '/reporting'
    },
    {
        title: 'HD Weekly',
        to: '/hdweeklystats'
    },
    {
        title: 'HD Query',
        to: '/hdQueryTool'
    },
    // {
    //     title: 'New SC Weekly',
    //     to: '/newscweeklystats'
    // },
    // {
    //     title: 'New SC Query',
    //     to: '/newSCQueryTool'
    // },
    {
        title: 'New SC Weekly',
        to: '/temporary'
    },
    {
        title: 'New SC Query',
        to: '/temporary'
    },
    {
        title: 'NCLT Weekly',
        to: '/ncltweeklystats'
    },
    {
        title: 'NCLT Query',
        to: '/ncltQueryTool'
    }
];
