import SubLinks from '../../components/header/SubLinks.jsx';
import { reportingLinks } from '../../config/standard-links.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import NcltQueryForm from '../../components/reporting/NcltQueryForm.jsx';

/**
 * Component for rendering the page for NCLT Query tool.
 * @returns {JSX.Element} NcltQueryToolPage component.
 */
const NcltQueryToolPage = () => {
    return (
        <div>
            <SubLinks links={reportingLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>NCLT Query</h2>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        width: '100%'
                    }}>
                    <NcltQueryForm />
                </div>
            </div>
        </div>
    );
};

export default NcltQueryToolPage;
