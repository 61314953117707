import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing computer-related data.
 *
 * @returns {Object} An object containing state variables and functions related to computer data.
 * @property {Object|null} computer - The returned computer (null if none returned).
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} createComputer - A function to create a new computer.
 */
const useComputers = () => {
    const [computer, setComputer] = useState(null);
    const [computers, setComputers] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Searches for computers based on specified criteria.
     *
     * @async
     * @function
     * @param {string} [searchString=''] - The search string for filtering computers.
     * @param {number} [currentPage=1] - The current page for pagination.
     * @param {number} [limit=40] - The number of computers to retrieve per page.
     * @returns {Promise<Array>} A promise that resolves with a list of returned computers.
     */
    const searchComputers = async (
        searchString = '',
        currentPage = 1,
        limit = 40
    ) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/computers/search/query?search=${searchString}&limit=${limit}&page=${currentPage}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const {
                results: returnedComputers,
                totalPages: returnedTotalPages
            } = await response.json();
            setComputers(returnedComputers);
            setTotalPages(returnedTotalPages);
            return returnedComputers;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Creates a new computer.
     *
     * @async
     * @function
     * @param {Object} newComputerFields - The fields for creating the new computer, passed from formik or elsewhere. Reference config/form-fields.js or validation/create-computer-validations.js for details on properties.
     * @param {string} officeSysName - the systematic name of the associated office
     * @returns {Promise<Computer>} A promise that resolves when the computer creation is complete with the new computer object.
     */
    const createComputer = async (newComputerFields, officeSysName) => {
        try {
            setLoading(true);
            setError(null);

            const checkSysName = newComputerFields.computerSysName
                .substring(
                    0,
                    newComputerFields.computerSysName.lastIndexOf('.')
                )
                .toLowerCase()
                .trim();

            if (checkSysName !== officeSysName) {
                throw new UserFriendlyError(
                    `Computer systematic name must contain the office systematic name: ${officeSysName}`
                );
            }

            const returnedComputers = await searchComputers(
                newComputerFields.computerSysName
            );

            if (returnedComputers.length > 0) {
                let nameExists = false;
                returnedComputers.forEach((c) => {
                    if (
                        newComputerFields.computerSysName === c.computerSysName
                    ) {
                        nameExists = true;
                    }
                });

                if (nameExists) {
                    throw new UserFriendlyError(
                        'Computer systematic name already exists'
                    );
                }
            }

            const path = `/computers`;
            const response = await callApi(path, 'post', newComputerFields);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const newComputer = await response.json();
            setComputer(newComputer);
            return newComputer;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Updates a computer.
     *
     * @async
     * @function
     * @param {Object} newComputerFields - The fields for creating the new computer, passed from formik or elsewhere. Reference config/form-fields.js or validation/create-computer-validations.js for details on properties.
     * @returns {Promise<Computer>} A promise that resolves when the computer creation is complete with the new computer object.
     */
    const updateComputer = async (
        computerId,
        updateComputerFields,
        originalComputer,
        officeSysName
    ) => {
        try {
            setLoading(true);
            setError(null);

            const updateComputerFieldsModified = { ...updateComputerFields };

            // If the computerSysName has changed, then check if one already exists
            if (
                updateComputerFields.computerSysName &&
                updateComputerFields.computerSysName !==
                    originalComputer.computerSysName
            ) {
                const returnedComputers = await searchComputers(
                    updateComputerFields.computerSysName
                );
                if (returnedComputers.length > 0) {
                    let nameExists = false;
                    returnedComputers.forEach((c) => {
                        if (
                            updateComputerFields.computerSysName ===
                            c.computerSysName
                        ) {
                            nameExists = true;
                        }
                    });

                    if (nameExists) {
                        throw new UserFriendlyError(
                            'Computer systematic name already exists'
                        );
                    }
                }

                const checkSysName = updateComputerFields.computerSysName
                    .substring(
                        0,
                        updateComputerFields.computerSysName.lastIndexOf('.')
                    )
                    .toLowerCase()
                    .trim();

                if (checkSysName !== officeSysName) {
                    throw new UserFriendlyError(
                        `Computer systematic name must contain the office systematic name: ${officeSysName}`
                    );
                }
            }
            // computerSysName has not changed, remove it from fields to pass to backend
            else {
                delete updateComputerFieldsModified.computerSysName;
            }

            // Remove any more fields that are not allowed
            delete updateComputerFieldsModified.officeId;
            delete updateComputerFieldsModified.id;
            delete updateComputerFieldsModified.credentials;

            const path = `/computers/${computerId}`;
            const response = await callApi(
                path,
                'patch',
                updateComputerFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedComputer = await response.json();
            setComputer(updatedComputer);
            return updatedComputer;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Duplicates a single computer and it's info by ID.
     *
     * @async
     * @function
     * @param {string} computerId
     * @returns {Promise<Computer>} A promise that resolves with the returned new computer.
     */
    const duplicateComputer = async (computerId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/computers/duplicate/${computerId}`;
            const response = await callApi(path, 'post');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedComputer = await response.json();
            setComputer(returnedComputer);
            return returnedComputer;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return {
        computer,
        computers,
        loading,
        error,
        createComputer,
        updateComputer,
        searchComputers,
        totalPages,
        duplicateComputer
    };
};

export default useComputers;
