import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import useUsers from '../../hooks/useUsers.js';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';
import capitalize from '../../utils/capitalize.js';

/**
 * Component for displaying user information on the dashboard.
 * @returns {JSX.Element} JSX element representing the DashboardUser component.
 */
const DashboardUser = () => {
    const { error, loading, getUser, user } = useUsers();

    /**
     * Fetches user data from the server.
     * @returns {void}
     */
    useEffect(() => {
        const getUserData = async () => {
            const token = sessionStorage.getItem('accessToken');
            const decoded = jwtDecode(token);

            await getUser(decoded.sub);
        };

        getUserData();
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                height: '100%'
            }}>
            <div className="reporting-tile-space-around">
                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
                {user && (
                    <>
                        {user.picture && (
                            <img
                                src={user.picture}
                                alt="Profile"
                                style={{
                                    flex: '0 1 45%',
                                    borderRadius: '20px'
                                }}
                            />
                        )}
                        <div className="reporting-user-info">
                            <h2 style={{ flex: '0 1 25%', margin: 0 }}>
                                {`${user.firstName} ${user.lastName}`}
                            </h2>
                            <p
                                style={{
                                    flex: '0 1 15%',
                                    margin: 0,
                                    fontStyle: 'italic'
                                }}>
                                {capitalize(user.role.name)}
                            </p>
                            {user.helpDeskTeam && (
                                <p style={{ flex: '0 1 15%', margin: 0 }}>
                                    {user.helpDeskTeam}
                                </p>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DashboardUser;
